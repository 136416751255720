.filter {
  padding-top: 18px;
  padding-bottom: 18px;
  display: flex;
  justify-content: space-between;

}

.wrapper {
  background: #FFFFFF;
}

.left_side, .right_side {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right_side {
  max-width: 340px;
  width: 100%;

}

.move_to {
  margin-left: 140px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  color: #000000;
}