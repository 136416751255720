.wrapper {
  position: relative;
  z-index: 12;
  width: 273px;
  margin-left: 10px;
}

.select {
  background: #FFFFFF;
  border: 1px solid var(--regular-bg-darken);
  border-radius: 4px;
  height: 34px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  cursor: pointer;
}

.more {
  position: absolute;
  z-index: 12;
  top: calc(100% + 15px);
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 0 12px;
}

.more_items {
  padding: 22px 0 10px 0;
}

.add_new {
  display: flex;
  flex-direction: column;
  margin-bottom: 11px;
}

.more_footer {
  display: flex;
  border-top: 1px solid var(--regular-bg-darken);
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  .plus_button {
    background: transparent;
    font-family: "Roboto", serif;
    border: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--regular-color);
    cursor: pointer;
  }
}

.empty {
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;

  color: var(--regular-color);
}