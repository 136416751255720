.block {
  position: relative;
}

.more {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  background: #FFFFFF;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 3;
  right: 0;
}