.form {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 4px;

  padding: 22px 22px 41px 22px ;
}

.field {
  width: 100%;
  display: flex;
  margin-top: 24px;
  flex-direction: column;
}

.title {
  text-align: center;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
}