.block {
  position: relative;
  max-width: 800px;
  overflow: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  max-height: 620px;
  padding: 22px 16px;
  background: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  p {
    padding-bottom: 14px;
    font-size: 14px;
  }
}

.footer {
  display: flex;
  justify-content: center;
}