.block {
  position: relative;
}

.select {
  border: 1px solid var(--regular-bg-darken);
  border-radius: 4px;
  height: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  cursor: pointer;
  min-width: 220px;

}

.select_text {
  font-size: 12px;
  color: var(--regular-bg-darker);
}

.statuses {
  min-height: 152px;
  position: absolute;
  top: calc(100% + 14px);
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  z-index: 20;
  padding: 10px;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.status_button {
  font-family: "Roboto", serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  border: 1px solid transparent;
  color: #868686;
  cursor: pointer;
  padding: 6px;
  border-radius: 4px;
  transition: all .3s;
  outline: none;
  font-size: 14px;
  line-height: 16px;
  &:not(:last-child) {
    margin-bottom: 4px;
  }

  &:hover {
    background: var(--regular-bg);
  }

  &.active {
    font-weight: 500;
    color: #000;
  }
}