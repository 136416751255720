.modal {
  position: relative;
  max-width: 500px;
  top: 50%;
  left: 50%;
  overflow: auto;
  transform: translate(-50%, -50%);
  outline: none;
  max-height: 620px;
  padding: 22px 16px;
  background: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.title {
  padding-bottom: 20px;
  text-align: center;
}

.items {
  display: flex;
  flex-wrap: wrap;

}

.item {
  width: 50%;
  padding-left: 10%;
}

.footer {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}