.block {
  position: relative;
}

.more {
  position: absolute;
  top: calc(100% + 15px);
  left: 0;
  background: #FFFFFF;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  width: 270px;
  padding: 27px 12px;
  z-index: 3;
}

.field {
  width: 100%;
  display: flex;
  margin-bottom: 12px;
  flex-direction: column;
}

.loader_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}