@import '../../index';

.wrapper {
  @include custom-scrollbar;
  background: transparent;
  position: relative;
  width: 500px;
  overflow: auto;
  padding: 0 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  max-height: 560px;
}

.close_button {
  position: sticky;
  padding: 4px;
  top: 12px;
  left: 100%;
  z-index: 10;
  width: 22px;
  height: 22px;
  border: none;
  border-radius: 4px;
  color: #333333;
  font-weight: bold;
  background: transparent;
  cursor: pointer;
  transition: all .3s;
  background: var(--regular-bg);
  &:hover {
    background: var(--regular-bg);
  }
}

.modal {
  padding: 12px 50px;
  position: relative;
  background: white;
  outline: none;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.field {
  width: 100%;
  display: flex;
  margin-top: 12px;
  flex-direction: column;
}

.row {
  display: flex;
  margin-top: 12px;
  .field {
    margin-top: 0;
  }
}

.delete_button {
  margin-left: 4px ;
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #000000;
  padding-bottom: 10px;
}

.button_wrapper {
  width: 486px;
  top: calc(290px + 50%);
  left: 50%;
  transform: translate(-50.5%, -50%);
  outline: none;
  position: fixed;
}

.main_title {
  padding-left: 6px;
  padding-top: 14px;
  font-weight: 500;
  font-size: 16px;
}