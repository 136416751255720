.block {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #868686;
  cursor: pointer;
  padding: 6px;
  border-radius: 4px;

  &:not(:last-child) {
    margin-bottom: 3px;
  }

  transition: all .3s;
  &.active {
    color: #000;
    font-weight: 500;
  }

  &:hover {
    background: var(--regular-bg);
  }
}