.picker {
  border-radius: 4px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  transition: all .3s;

}

.wrapper {
  position: relative;
  border-radius: 4px;
}

.colors {
  outline: none;
  display: flex;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 352px;
  flex-wrap: wrap;
  z-index: 3;
  background: transparent;
  .picker {
    margin: 4px;
    height: 80px;
    width: 80px;
    transition: opacity .3s;
    &:hover {
      opacity: .8;
    }

    &_active {
      position: relative;
      &::before {
        position: absolute;
        content: '';
        top: 25%;
        left: 25%;
        width: 50%;
        height: 50%;
        background: url('../../assets/check-mark.svg') no-repeat ;
        background-size: cover;
      }
    }

  }
}