.block {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
}

.code_field {
  width: 240px;
  margin-right: 10px;
}

.select_field {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
}

.select_field_code {
  font-size: 12px;
  color: #B6B6B6;
}

.delete_button {
  margin-left: 4px ;
}

.error_message {
  color: #f44336;
  margin-left: 14px;
  margin-right: 14px;
  margin-top: 4px;
  font-size: 0.75rem;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}