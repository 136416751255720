@import '../../index';

.list_wrapper {
  background: var(--regular-bg);
  width: 100%;
}

.list {
  display: flex;
  @include custom-scrollbar;
  &::-webkit-scrollbar {
    height: 4px;
    cursor: pointer;
  }

  padding-bottom: 4px;

  overflow-x: auto;
}

.link_wrapper {

  &:not(:last-child) {
    margin-right: 15px;
  }
}

.container_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item {
  white-space: nowrap;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  padding: 5px 0 5px 16px;
  background: var(--regular-bg-darker);
  border-radius: 4px;
  display: flex;
  transition: all .3s;
  &:hover {
    opacity: .7;
    background: var(--secondary-accent-color);
  }


  &.active {
    background: var(--secondary-accent-color);
    cursor: default;
  }
}

.item_default {
  padding: 8px 16px;
}

.item_text {
  white-space: nowrap;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  padding: 3px 9px 3px 0;
}

.button {
  position: relative;
  z-index: 3;
  padding: 0 10px;
  border: none;
  border-left: 1px solid #fff;
  background: transparent;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
  transition: all .3s;
  outline: none;

}