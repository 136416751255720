@import "../../index";
.table {
  display: flex;
  flex-direction: column;
  height: calc(100% - 66px - 84px - 40px);
  transition: all .3s;
  position: relative;
}


.spinner_wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #FFFFFF99;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.sort_button {
  display: flex;
  justify-content: space-between;
  transition: all .3s;
  align-items: center;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: #FFFFFF33;
  }
}

.non_events {
  pointer-events: none;
}

.thead {
  background: var(--regular-color);
  color: #fff;

  .field {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 15px;
    font-weight: 600;
    position: relative;
    text-transform: capitalize;


    &:not(:first-child) {
      &::before {
        content: '';
        position: absolute;
        width: 1px;
        left: -12px;
        height: 26px;
        bottom: 9px;
        background: #828282;
      }
    }
  }

  .last_field {
    padding-top: 0;
    display: flex;
    align-items: center;
    width: 38px;
    background: transparent;
    border-left: 1px solid transparent;
  }
}

.no_content {
  margin: auto;
  text-align: center;
  padding-top: 20px;
  font-size: 28px;
}

.field {
  width: calc(20% - 38px/5);
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  padding-right: 8px;
  padding-top: 24px;
  padding-bottom: 24px;
}
.row {
  display: flex;
  justify-content: space-between;

}

.second_row {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #333333;
  b {
    font-weight: bold;
  }

  padding-bottom: 20px !important;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: 15px;
    height: 100%;
    width: 38px;
    background: #F0F4FF;
  }
}

.second_row_item {
  padding-left: 5px;
  max-width: 400px;
}

.last_field {
  width: 38px;
  padding-top: 10px;
  background: var(--regular-bg);
  border-left: 1px solid var(--regular-bg);
}

.tbody {
  overflow-y: auto;
  @include custom-scrollbar;
  .field {
    color: #333333;
    overflow: hidden;

  }

  .row {
    position: relative;
    border-left: 3px solid transparent;
  }
}

.arrow {
  cursor: pointer;
  position: absolute;
  left: -25px;
  top: 19px;
}

.lead_status {
  position: absolute;
  left: 0;
  width: 3px;
  height: 100%;
  cursor: help;
}

.row_wrapper {
  transition: all .3s;
  &:hover {
    .controls {
      visibility: visible;
      opacity: 1;
      right: -25px;
    }
  }



}

.row_wrapper.active {
  background: #F0FFE0;
  .last_field {
    background: transparent;
  }

  &.combine_mode {
    .controls {
      visibility: visible;
      opacity: 1;
      top: -2px;
      right: -25px;
    }
  }
}

.controls {
  transition: all .3s;
  visibility: hidden;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  right: 0;
  height: 100%;
}

.apply_combine {
  border: none;
  background: none;
  cursor: pointer;

  padding: 3px 8px;
  border-radius: 4px;

  
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #828282;

  transition: all .3s;
  position: relative;
  &:hover {
    background: #81BB41;
    color: #FFFFFF;
  }
}

.status_color {
  background: red;
}

.scroll_loader {
  display: flex;
  justify-content: center;
  padding: 30px 0;
}
